import * as MomoCenterActions from '../../Actions/MomoCenter';
import * as AppActions from '../../AppActions';

const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  console.log('fetchMany', startDate, endDate);
  try {
    AppActions.setLoading(true);
    const {total, results} = await MomoCenterActions.fetchProductList(
      {
        /*
        update_time_from: Math.floor(new Date(startDate).getTime() / 1000),
        update_time_to: Math.floor(new Date(endDate).getTime() / 1000),
        item_status: [itemStatus],
      */
      },
      paging,
    );
    onSuccess({total, results});
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = () => [
  {
    title: 'GOODS_CODE',
    key: 'GOODS_CODE',
    dataIndex: 'GOODS_CODE',
    width: 100,
    fixed: 'left',
  },
  {title: 'GOODS_NAME', key: 'GOODS_NAME', dataIndex: 'GOODS_NAME', width: 500},
  {
    title: 'GOODSDT_CODE',
    key: 'GOODSDT_CODE',
    dataIndex: 'GOODSDT_CODE',
    width: 100,
  },
  {
    title: 'GOODSDT_INFO',
    key: 'GOODSDT_INFO',
    dataIndex: 'GOODSDT_INFO',
    width: 200,
  },
  {
    title: 'ENTP_GOODS_NO',
    key: 'ENTP_GOODS_NO',
    dataIndex: 'ENTP_GOODS_NO',
    width: 100,
  },
  {
    title: 'SALEGB_NAME',
    key: 'SALEGB_NAME',
    dataIndex: 'SALEGB_NAME',
    width: 100,
  },
  {
    title: 'SALE_PRICE',
    key: 'SALE_PRICE',
    dataIndex: 'SALE_PRICE',
    width: 100,
  },
];

export {pageSizeOptions, sortOptions, fetchMany, makeColumns};
